import React, { useRef } from "react";

import { motion, useScroll, useTransform } from "framer-motion";

interface ParallaxImageProps {
  imageUrl: string;
  altText: string;
}

const ParallaxImage: React.FC<ParallaxImageProps> = ({ imageUrl, altText }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const y = useTransform(scrollYProgress, [0, 1], ["0%", "-33%"]);

  return (
    <section
      id={"ParalaxImage"}
      className="relative overflow-hidden bg-black w-full aspect-[16/6] lg:aspect-[16/5]"
      ref={ref}
    >
      <motion.div style={{ y }}>
        <img
          src={imageUrl}
          alt={altText}
          className={"object-cover w-full aspect-[16/10]"}
        />
      </motion.div>
      <h1
        className={
          "absolute bottom-[6vw] left-[6vw] text-[8vw] text-white text-5xl callista"
        }
      >
        {altText}
      </h1>
    </section>
  );
};

export default ParallaxImage;
