const firebaseConfig = {
  apiKey: "AIzaSyDBvyfskzsA8_Guj_iwMUXIuBiuKsjfXm0",
  authDomain: "beeldfotobeeld-ab98d.firebaseapp.com",
  projectId: "beeldfotobeeld-ab98d",
  storageBucket: "beeldfotobeeld-ab98d.appspot.com",
  messagingSenderId: "1030508181292",
  appId: "1:1030508181292:web:5a41b805d6cebaaeb11e89",
  measurementId: "G-S2WRHRK784",
};

export default firebaseConfig;
