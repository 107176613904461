// ImageSlider.tsx
import React from "react";
import Slider from "react-slick";

import { collection, where, query } from "firebase/firestore";
import { useFirestoreCollectionData, useFirestore } from "reactfire";

import mainImage from "../assets/2PzdKttV0OshdSUFp0C0_2000x2000.webp";

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  cssEase: "linear",
  arrows: false,
};

const ImageSlider: React.FC = () => {
  const firestore = useFirestore();

  const imageCol = collection(firestore, "images");
  const imgQuery = query(imageCol, where("labels", "array-contains", "slider"));
  const { data: slides = [] } = useFirestoreCollectionData(imgQuery);

  const allSlides = [
    {
      uuid: "2PzdKttV0OshdSUFp0C0",
      imageUrl: mainImage,
      altText: "Graancirkel",
    },
    ...slides,
  ];

  return (
    <section id={"MainSlider"} className="min-w-full h-[100svh]">
      <Slider {...sliderSettings}>
        {allSlides.map((image) => (
          <div key={image.uuid}>
            <img
              src={image.imageUrl}
              alt={image.altText}
              className="w-screen h-[100svh] object-cover"
            />
          </div>
        ))}
      </Slider>
      <div
        className="callista absolute bottom-[10vh] min-w-full pointer-events-none text-center text-white flex flex-col"
        style={{
          fontSize: "calc(2vw + 2vh + 2vmin)",
        }}
      >
        Open lucht atelier
        <span
          style={{
            fontSize: "calc(0.5vw + 0.5vh + 0.5vmin)",
            fontFamily: "Varela",
          }}
        >
          53°15′55″N, 4°88′59″O
        </span>
      </div>
    </section>
  );
};

export default ImageSlider;
