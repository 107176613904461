import React from "react";

import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, query, orderBy } from "firebase/firestore";

import Moment from "react-moment";

const Expositions: React.FC = () => {
  const firestore = useFirestore();
  const expoCollection = collection(firestore, "expositions");
  const expoQuery = query(expoCollection, orderBy("date", "desc"));

  const { data: expositions = [] } = useFirestoreCollectionData(expoQuery);

  return (
    <section
      id={"Expositions"}
      className="flex items-center flex-col justify-center bg-gray-200 shadow-inner"
    >
      <div className="flex flex-col items-center justify-center">
        <h1 className="p-8 text-2xl font-bold">EXPOSITIES</h1>
        <h2 className="pb-8 w-[80vw] sm:w-[70vw] md:w-[60vw] lg:w-[50vw] text-justify">
          Hieronder een lijst met locaties waar BeeldFotoBeeld heeft gexposeerd.
          Heeft u een mooie ruimte en wilt u dat BeeldFotoBeeld daar komt
          exposeren? Neem dan contact op onderaan de pagina.
        </h2>
      </div>
      <div className="pb-8 w-[100vw] md:w-[90vw] lg:w-[80vw] flex flex-wrap justify-center align-center">
        {expositions.map((expo) => (
          <div
            className={
              "flex flex-col text-center items-center justify-between p-4 w-[40%] sm:w-[40%] md:w-[30%] lg:w-[23%] max-w-[280px] m-2 sm:m-2 md:m-3 lg:m-4 bg-white rounded shadow-md hover:shadow-xl transition duration-300 ease-in-out"
            }
            key={expo.NO_ID_FIELD}
          >
            <div>
              <h1 className={"font-bold text-center "}>{expo.location}</h1>
              <p className={"pb-2 italic"}>{expo.city}</p>
            </div>
            <Moment
              unix
              format="MM/YYYY"
              className={"text-gray-500 italic text-xs"}
            >
              {expo.date?.seconds}
            </Moment>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Expositions;
