import React from "react";
import ReactDOM from "react-dom/client";

import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import "./index.css";

import {
  FirebaseAppProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp,
} from "reactfire";

import {
  MainSlider,
  ParallaxImage,
  PhotoGrid,
  Expositions,
} from "./components";

import firebaseConfig from "./services/firebase";

const BeeldFotoBeeld: React.FC = () => {
  const firestoreInstance = getFirestore(useFirebaseApp());
  const storageInstance = getStorage(useFirebaseApp());

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <StorageProvider sdk={storageInstance}>
        <MainSlider />
        <PhotoGrid />
        <Expositions />
        <ParallaxImage
          imageUrl={
            "https://storage.googleapis.com/beeldfotobeeld-ab98d.appspot.com/images/johndevos_2000x2000.webp"
          }
          altText={"John de Vos"}
        />
      </StorageProvider>
    </FirestoreProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <BeeldFotoBeeld />
    </FirebaseAppProvider>
  </React.StrictMode>
);
