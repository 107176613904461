import React, { useState } from "react";

import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection } from "firebase/firestore";

import { motion, AnimatePresence } from "framer-motion";

const PhotoGrid: React.FC = () => {
  const [photoIndex, setPhotoIndex] = useState<number | null>(null);

  const firestore = useFirestore();
  const sliderCollection = collection(firestore, "images");

  const { data: slides = [] } = useFirestoreCollectionData(sliderCollection);

  return (
    <section
      id={"PhotoGrid"}
      className="p-8 bg-white flex justify-center items-center flex-col"
    >
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-2xl font-bold">MIJN WERK</h1>
        <p className="text-sm p-4">Klik op een foto om deze te vergroten</p>
      </div>

      <div className="px-8 w-[100vw] md:w-[90vw] lg:w-[80vw] flex flex-wrap justify-center align-center">
        {slides.map((image, index: number) => (
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={
              "cursor-pointer rounded w-[50%] sm:w-[50%] md:w-[33%] lg:w-[25%] aspect-[1/1] p-2 sm:p-2 md:p-3 lg:p-4 pointer-events-none"
            }
            onClick={() => setPhotoIndex(index + 1)}
            key={image.uuid}
          >
            <motion.img
              layoutId={String(index + 1)}
              src={image.imageUrl}
              alt={image.altText}
              className={
                "h-[100%] w-[100%] rounded pointer-events-auto shadow-md hover:shadow-xl object-cover object-center"
              }
            />
          </motion.div>
        ))}
      </div>
      <AnimatePresence>
        {photoIndex && (
          <div>
            <motion.div
              className="fixed top-0 inset-0 bg-black bg-opacity-50 z-10 flex justify-center items-center backdrop-filter backdrop-blur-md"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
            <div
              className="fixed top-0 inset-0 flex flex-col justify-center z-20 items-center"
              onClick={() => setPhotoIndex(null)}
            >
              <motion.img
                layoutId={String(photoIndex)}
                className={
                  "rounded object-center object-cover max-h-[85%] max-w-[85%]"
                }
                src={slides[photoIndex - 1].imageUrl}
                alt={slides[photoIndex - 1].altText}
              />
              <motion.p
                className="p-4 text-white sm:text-xl md:text-2xl lg:text-4xl"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {slides[photoIndex - 1].title}
              </motion.p>
            </div>
          </div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default PhotoGrid;
